export const getOverviewData = () => {
    return [
        {
            sectionImage: 'Overview/modelo-cantina.png',
            sectionTitle: 'Mondelo Cantina Club',
            paragraph: 'With immaculate finishes, clear Field views, and high end style, this event space has all the components to create an incredible experience.',
            area: '20,600',
            reception: '700',
            // banquet: '300'
        }
    ]
}

export const getCarouselImages = () => {
    return [
        'MondeloCantinaClub/Photos/modelo-cantina-club-bg.jpg',
        'MondeloCantinaClub/Photos/modelo-cantina-club-01.jpg',
        'MondeloCantinaClub/Photos/modelo-cantina-club-02.jpg',
        'MondeloCantinaClub/Photos/modelo-cantina-club-03.jpg',
        'MondeloCantinaClub/Photos/modelo-cantina-club-04.jpg',
        'MondeloCantinaClub/Photos/modelo-cantina-club-05.jpg',
        'MondeloCantinaClub/Photos/modelo-cantina-club-06.jpg',
        'MondeloCantinaClub/Photos/modelo-cantina-club-07.jpg',
        'MondeloCantinaClub/Photos/modelo-cantina-club-08.jpg',
        'MondeloCantinaClub/Photos/modelo-cantina-club-09.jpg',
        'MondeloCantinaClub/Photos/modelo-cantina-club-10.jpg',
        'MondeloCantinaClub/Photos/modelo-cantina-club-11.jpg'
    ]
}

export const getFieldViewImages = () => {
    return [
        'MondeloCantinaClub/FieldView/VR_Section_C112_32_13_Black.jpg',
        'MondeloCantinaClub/FieldView/VR_Section_C112_32_13_Down.jpg',
        'MondeloCantinaClub/FieldView/VR_Section_C112_32_13_Front.jpg',
        'MondeloCantinaClub/FieldView/VR_Section_C112_32_13_Left.jpg',
        'MondeloCantinaClub/FieldView/VR_Section_C112_32_13_Right.jpg',
        'MondeloCantinaClub/FieldView/VR_Section_C112_32_13_Up.jpg'
    ]
}

export const getLayoutImages = () => {
    return [
        'MondeloCantinaClub/Layout/layout-modelo-cantina-club.jpg'
    ]
}
